import useDialog from '@/hooks/useDialog'
import { Money, NiftyCard } from '@/elements/src'
import { useMemo } from 'react'
import useNiftyType from '@/hooks/useNiftyType'
import {
  usePrimaryMarketPurchaseIntent,
  useRegenerateQuote,
} from '@/hooks/usePurchaseIntent'
import { Box, Skeleton, Stack, Typography, useTheme } from '@mui/material'
import getToastifyOptions from '@/utils/toastify-options'
import { toast } from 'react-toastify'
import { Currency } from '@/types/payment'
import useMediaQueryState from '@/hooks/useMediaQueryState'
import { useQueryClient } from '@tanstack/react-query'
import _isEmpty from 'lodash/isEmpty'
import { getQuoteBreakdownItems } from '../../checkout/[contractAddress]/[niftyType]/lineItems'
import PaymentForm from '../../checkout/_components/PaymentForm'
import PaymentSelect from '../../checkout/_components/PaymentSelect'
import useDrawingCheckout from '../_hooks/useDrawingCheckout'
import { PageNiftyTypeKey } from '../../itemdetail/primary/[contractAddress]/[niftyType]/constants'

export type DrawingCheckoutProps = {
  contractAddress: string
  niftyType: string
  purchaseIntentId: string
  hideHeading?: boolean
  openDialog: ReturnType<typeof useDialog>['openDialog']
  children?: React.ReactNode
  preventRedirect?: boolean
  disablePurchase?: boolean
  onPurchaseIntentStatus?: (status: 'init' | 'success' | 'error' | null) => void
  onPaymentStatus?: (
    status: string,
    remove: () => void,
    quantity: number,
  ) => void
  onPaymentSuccess?: (paymentAmount: string, paymentCurrency: Currency) => void
  onPaymentError?: (err?: unknown) => void
  onPaymentInit?: () => void
  closeModal: () => void
}

export type NiftyConfig = { contractAddress: string; niftyType: string }

const DrawingCheckout = ({
  purchaseIntentId,
  contractAddress,
  niftyType,
  hideHeading = false,
  children,
  openDialog,
  onPaymentInit,
  onPaymentSuccess,
  onPaymentError,
  onPurchaseIntentStatus,
  preventRedirect = false,
  disablePurchase = false,
  closeModal,
}: DrawingCheckoutProps) => {
  const theme = useTheme()
  const isMobile = useMediaQueryState(theme.breakpoints.down('md'))
  const queryClient = useQueryClient()

  const niftyConfig: NiftyConfig = { contractAddress, niftyType }
  const { data: nifty } = useNiftyType(niftyConfig)

  const lineItems = useMemo(
    () => getQuoteBreakdownItems(nifty?.is_collecting_tax ?? false),
    [nifty],
  )

  const dropType = nifty?.primary_sale_type
  const regenerateQuote = useRegenerateQuote(purchaseIntentId, dropType)

  const {
    paymentRequired,
    afterSubmit,
    updateFailed,
    isUpdating,
    purchaseIntent,
    paymentCurrency,
    paymentMethod,
    setPaymentCurrency,
    setPaymentMethod,
    quantity,
    setQuantity,
    maxQuantityOverride,
  } = useDrawingCheckout({
    nifty,
    purchaseIntentId,
    niftyConfig,
    openDialog,
    onPurchaseIntentStatus,
    preventRedirect,
  })

  return (
    <Box
      sx={{
        display: isMobile ? 'inline-block' : 'flex',
        gap: '100px',
        width: '100%',
      }}
    >
      <Box sx={{ width: isMobile ? '100%' : '50%' }}>
        <Stack
          direction='row'
          sx={{
            marginTop: '40px',
            width: isMobile ? '300px' : '100%',
            height: isMobile ? '380px' : '500px',
            display: 'flex',
            flexDirection: 'column',
            padding: '12px',
            border: '1px solid rgba(230, 230, 230, 1)',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            borderRadius: '16px',
            boxShadow: '0px 8px 20px rgba(0, 0, 0, 0.08)',
          }}
        >
          <div
            id='inner-image-container'
            style={{
              width: isMobile ? '250px' : '360px',
              height: isMobile ? '250px' : '360px',
              position: 'relative',
              marginLeft: isMobile ? '10px' : '30px',
              marginRight: isMobile ? '20px' : '30px',
              marginBottom: '12px',
            }}
          >
            {_isEmpty(nifty) ? (
              <Skeleton
                variant='rectangular'
                animation='wave'
                width={200}
                height={200}
              />
            ) : (
              <NiftyCard
                asset={{
                  src: nifty?.niftyImageURL,
                }}
              />
            )}
          </div>
          {!_isEmpty(nifty) && (
            <div
              style={{
                alignContent: 'flex-start',
                marginLeft: isMobile ? '10px' : '30px',
              }}
            >
              <Typography
                sx={{
                  fontSize: isMobile ? 15 : 20,
                  fontWeight: 600,
                  marginTop: '16px',
                }}
              >
                {nifty.niftyTitle}
              </Typography>
              <Typography
                sx={{
                  fontSize: isMobile ? 15 : 20,
                  fontWeight: 600,
                  marginTop: '16px',
                }}
              >
                <Money
                  value={[
                    nifty.price_amount ?? 0,
                    nifty.price_currency ?? Currency.USD,
                  ]}
                  convertFromCents={false}
                />
              </Typography>
            </div>
          )}
        </Stack>
      </Box>
      <Stack
        sx={{
          width: isMobile ? '100%' : '50%',
          marginTop: isMobile ? '25px' : '40px',
        }}
      >
        <Typography variant='h2' sx={{ marginBottom: '25px' }}>
          Enter Drawing
        </Typography>
        {paymentRequired && (
          <PaymentSelect
            MenuProps={hideHeading ? { sx: { zIndex: 53001 } } : undefined}
            purchaseIntent={purchaseIntent}
            paymentMethod={paymentMethod}
            setPaymentMethod={setPaymentMethod}
            paymentCurrency={paymentCurrency}
            setPaymentCurrency={setPaymentCurrency}
          />
        )}
        <PaymentForm
          isPrimaryMarket
          nifty={nifty}
          afterSubmit={afterSubmit}
          canSubmit={!updateFailed && !isUpdating}
          isUpdating={isUpdating}
          invoiceSectionTitle='Confirm Entry'
          lineItems={lineItems}
          openDialog={openDialog}
          paymentMethod={purchaseIntent!.paymentMethod}
          paymentCurrency={paymentCurrency}
          setPaymentCurrency={setPaymentCurrency}
          purchaseIntentId={purchaseIntentId}
          regenerateQuote={regenerateQuote}
          submitButtonText='Confirm Entry'
          dropType={nifty?.primary_sale_type}
          dropEndTime={nifty?.scheduledEndDateTime}
          usePaymentInfo={usePrimaryMarketPurchaseIntent}
          bottomAnchorChildren={children}
          disablePurchase={disablePurchase}
          onPaymentInit={onPaymentInit}
          quantity={quantity}
          setQuantity={setQuantity}
          minimumQuantity={1}
          maximumQuantity={
            maxQuantityOverride ?? nifty?.max_quantity_per_checkout
          }
          onPaymentSuccess={() => {
            // trigger refetch of bid data
            // do this after 2 seconds as it takes the backend time to update total sold count
            setTimeout(() => {
              queryClient.invalidateQueries([
                PageNiftyTypeKey,
                contractAddress,
                niftyType,
              ])
            }, 2000)

            toast.success(
              <>
                Your payment for {nifty?.niftyTitle} was successfully submitted!
              </>,
              getToastifyOptions('success'),
            )
            closeModal()
            onPaymentSuccess?.(
              purchaseIntent?.bidAmount ?? '0',
              nifty?.price_currency ?? Currency.USD,
            )
          }}
          onPaymentError={onPaymentError}
        />
      </Stack>
    </Box>
  )
}

export default DrawingCheckout
